import { http } from './config'
import authHeader from './auth-header';

export default {

  lista_leads: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

    const params= {
        'data-inicial': dataI,
        'data-final': dataF,
        'empresa': nomeEmpresa,
        'tipo': tipo,
        'filtro': filtro,
        'opcao': opcao,
        'produto': produto,
    }

    return http.get('/bitrix/lista-lead/', {
    params: params,
    headers: authHeader()   
    })  
    },


            
}
